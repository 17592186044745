<template>
  <v-card
    class="editor"
    :flat="flat"
  >
    <div
      class="editor__header pb-2"
      :editor="editor"
      v-if="editor && !hideToolbar"
    >
      <v-container
        fluid
        class="px-0"
        v-if="false"
      >
        <v-row dense>
          <v-col cols="auto">
            <v-btn
              @click="editor.chain().focus().toggleBold().run()"
              :class="{ 'is-active': editor.isActive('bold') }"
              outlined
              small
              :style="(!disabled && editor.isActive('bold')) ? 'background-color: #E0E0E0;' : ''"
              :disabled="disabled"
            >
              <v-icon>mdi-format-bold</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="editor.chain().focus().toggleItalic().run()"
              :class="{ 'is-active': editor.isActive('italic') }"
              outlined
              small
              :style="(!disabled && editor.isActive('italic')) ? 'background-color: #E0E0E0;' : ''"
              :disabled="disabled"
            >
              <v-icon>mdi-format-italic</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="editor.chain().focus().toggleStrike().run()"
              :class="{ 'is-active': editor.isActive('strike') }"
              outlined
              small
              :style="(!disabled && editor.isActive('strike')) ? 'background-color: #E0E0E0;' : ''"
              :disabled="disabled"
            >
              <v-icon>mdi-format-strikethrough</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              @click="editor.chain().focus().toggleCode().run()"
              :class="{ 'is-active': editor.isActive('code') }"
              outlined
              small
              :style="(!disabled && editor.isActive('code')) ? 'background-color: #E0E0E0;' : ''"
              :disabled="disabled"
            >
              <v-icon>mdi-code-tags</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        @click="editor.chain().focus().undo().run()"
        outlined
        small
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-undo</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().redo().run()"
        outlined
        small
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-redo</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
        outlined
        small
        :style="(!disabled && editor.isActive('bold')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
        outlined
        small
        :style="(!disabled && editor.isActive('italic')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
        outlined
        small
        :style="(!disabled && editor.isActive('strike')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-strikethrough</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="{ 'is-active': editor.isActive('underline') }"
        outlined
        small
        :style="(!disabled && editor.isActive('underline')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-underline</v-icon>
      </v-btn>
      <v-btn
        @click="clearFormatting"
        outlined
        small
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-clear</v-icon>
      </v-btn>
      <v-btn
        @click="setLink"
        :class="{ 'is-active': editor.isActive('link') }"
        outlined
        small
        :style="(!disabled && editor.isActive('link')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-link</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().unsetLink().run()"
        v-if="editor.isActive('link')"
        outlined
        small
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-link-off</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleHighlight().run()"
        :class="{ 'is-active': editor.isActive('highlight') }"
        outlined
        small
        :style="(!disabled && editor.isActive('highlight')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-marker</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setTextAlign('left').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        v-if="show"
        outlined
        small
        :style="(!disabled && editor.isActive({ textAlign: 'left' })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-align-left</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setTextAlign('center').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        v-if="show"
        outlined
        small
        :style="(!disabled && editor.isActive({ textAlign: 'center' })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-align-center</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setTextAlign('right').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
        v-if="show"
        outlined
        small
        :style="(!disabled && editor.isActive({ textAlign: 'right' })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-align-right</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setTextAlign('justify').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
        v-if="show"
        outlined
        small
        :style="(!disabled && editor.isActive({ textAlign: 'justify' })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-align-justify</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        outlined
        small
        :style="(!disabled && editor.isActive('paragraph')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-paragraph</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        outlined
        small
        :style="(!disabled && editor.isActive('heading', { level: 1 })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-header-1</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        outlined
        small
        :style="(!disabled && editor.isActive('heading', { level: 2 })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-header-2</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        outlined
        small
        :style="(!disabled && editor.isActive('heading', { level: 3 })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-header-3</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        outlined
        small
        :style="(!disabled && editor.isActive('heading', { level: 4 })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-header-4</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
        outlined
        small
        :style="(!disabled && editor.isActive('heading', { level: 5 })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-header-5</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
        outlined
        small
        :style="(!disabled && editor.isActive('heading', { level: 6 })) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-header-6</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        outlined
        small
        :style="(!disabled && editor.isActive('bulletList')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        outlined
        small
        :style="(!disabled && editor.isActive('orderedList')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
        outlined
        small
        :style="(!disabled && editor.isActive('code')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-code-tags</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
        outlined
        small
        :style="(!disabled && editor.isActive('codeBlock')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-code-not-equal-variant</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }"
        outlined
        small
        :style="(!disabled && editor.isActive('blockquote')) ? 'background-color: #E0E0E0;' : ''"
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-format-quote-close</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setHorizontalRule().run()"
        outlined
        small
        :disabled="disabled"
        class="formatButton"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-btn
        @click="editor.chain().focus().setHardBreak().run()"
        v-if="show"
        outlined
        small
        :disabled="disabled"
        class="formatButton"
      >
        {{ $t('hardBreak') }}
      </v-btn>
    </div>
    <editor-content
      class="editor__content pb-1"
      :editor="editor"
      style="border-radius: 4px;"
      :style="backgroundColor ? 'background-color:' + backgroundColor + ';' : ''"
      :class="disabled ? 'text--disabled' : ''"
      v-if="disabled || (!disabled && !showPlain)"
    />
    <v-textarea
      hide-details
      height="250"
      class="pb-1"
      outlined
      :disabled="disabled"
      v-model="value"
      v-if="!disabled && showPlain"
    />
    <div class="editor__footer pb-2">
      <v-btn
        class="caption text-capitalize"
        :disabled="disabled"
        @click="showPlainText"
        text
        x-small
      >
        {{ $t('showPlainText') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'

export default {
  name: "TipTapRichTextEditor",
  props: {
    value: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    flat: {
      type: Boolean
    },
    outlined: {
      type: Boolean
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    hideToolbar: {
      type: Boolean
    }
  },
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null,
      show: false,
      showPlain: false
    }
  },
  mounted() {
    this.editor = new Editor({
      editable: !this.disabled,
      extensions: [
        StarterKit,
        Underline,
        TextAlign,
        Highlight,
        Link
      ],
      content: this.value,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      }
    })
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value

      if(isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    }
  },
  methods: {
    clearFormatting() {
      this.editor.chain().focus().unsetAllMarks().run()
      this.editor.chain().focus().clearNodes().run()
    },
    showPlainText() {
      this.showPlain = !this.showPlain
    },
    setLink() {
      const url = window.prompt('URL')

      this.editor.chain().focus().setLink({ href: url }).run()
    },
  },
  computed: {

  },
  beforeDestroy() {
    this.editor.destroy()
  }
}
</script>

<style scoped>
.editor__content>>>.ProseMirror {
  border: 1px black solid;
  border-radius: 4px;
  padding: 4px 8px;
  min-height: 250px;
}
.formatButton {
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>
